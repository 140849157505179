import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { checkEmail, verifyOtp, resetPassword } from "../services/authService";
import WestIcon from "@mui/icons-material/West";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ForgotPassword = () => {
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [emailToken, setEmailToken] = useState(null);
  const [otpToken, setOtpToken] = useState(null);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const emailSchema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
  });

  const otpSchema = yup.object().shape({
    otp: yup
      .string()
      .length(4, "OTP must be exactly 4 digits")
      .required("OTP is required"),
  });

  const passwordSchema = yup.object().shape({
    newPassword: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("New password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const handleEmailSubmit = async (data, { resetForm }) => {
    setErrorMessage("");
    try {
      const token = await checkEmail(data.email);
      setEmailToken(token);
      setStep(2);
      resetForm();
    } catch (error) {
      setErrorMessage(error.detail || "Failed to send OTP. Please try again.");
    }
  };

  const handleOtpSubmit = async (data) => {
    setErrorMessage("");
    try {
      const token = await verifyOtp(data.otp, emailToken);
      setOtpToken(token);
      setStep(3);
    } catch (error) {
      setErrorMessage(error.detail || "Invalid OTP. Please try again.");
    }
  };

  const handlePasswordSubmit = async (data) => {
    setErrorMessage("");
    try {
      await resetPassword(otpToken, data.newPassword, data.confirmPassword);
      navigate("/login");
    } catch (error) {
      setErrorMessage(
        error.detail || "Failed to reset password. Please try again."
      );
    }
  };

  return (
    <section className="main-auth">
      <div className="main-auth-form">
        {step === 1 ? (
          <>
            <h3>Forgot Password</h3>
            <Formik
              validationSchema={emailSchema}
              onSubmit={handleEmailSubmit}
              initialValues={{ email: "" }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit} className="mt-4 py-1">
                  <Form.Group controlId="email">
                    <Form.Control
                      type="email"
                      placeholder="Email address"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {errorMessage && (
                    <div className="text-danger mt-3">{errorMessage}</div>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 mt-4"
                  >
                    Send OTP
                  </Button>
                  <div className="mt-4">
                    <NavLink to="/sign-in" className="text-decoration-none">
                      <strong>
                        <WestIcon /> Back to Sign In
                      </strong>
                    </NavLink>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        ) : step === 2 ? (
          <>
            <h3>Enter OTP</h3>
            <Formik
              validationSchema={otpSchema}
              onSubmit={handleOtpSubmit}
              initialValues={{ otp: "" }}
            >
              {({ handleSubmit, values, errors, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit} className="mt-4 py-1">
                  <OTPInput
                    value={values.otp}
                    onChange={(otp) => setFieldValue("otp", otp)}
                    numInputs={4}
                    renderSeparator={<span>{"\t"}</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        style={{
                          width: "40px",
                          height: "40px",
                          margin: "0 5px",
                          textAlign: "center",
                          border: "2px solid black",
                        }}
                      />
                    )}
                  />
                  {errors.otp && (
                    <div className="text-danger mt-2">{errors.otp}</div>
                  )}
                  {errorMessage && (
                    <div className="text-danger mt-3">{errorMessage}</div>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 mt-4"
                  >
                    Verify OTP
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <h3>Reset Password</h3>
            <Formik
              validationSchema={passwordSchema}
              onSubmit={handlePasswordSubmit}
              initialValues={{ newPassword: "", confirmPassword: "" }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit} className="mt-4 py-1">
                  {/* New Password */}
                  <Form.Group controlId="newPassword" className="mb-3">
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        type={showNewPassword ? "text" : "password"}
                        placeholder="New Password"
                        name="newPassword"
                        value={values.newPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.newPassword}
                      />
                      <FontAwesomeIcon
                        icon={showNewPassword ? faEyeSlash : faEye}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: errors.newPassword ? "30%" : "50%", // Adjust top dynamically
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "#6c757d",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.newPassword}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  {/* Confirm Password */}
                  <Form.Group controlId="confirmPassword" className="mb-3">
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.confirmPassword}
                      />
                      <FontAwesomeIcon
                        icon={showConfirmPassword ? faEyeSlash : faEye}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: errors.confirmPassword ? "30%" : "50%", // Adjust top dynamically
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "#6c757d",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  {errorMessage && (
                    <div className="text-danger mt-3">{errorMessage}</div>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 mt-4"
                  >
                    Reset Password
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </section>
  );
};

export default ForgotPassword;
